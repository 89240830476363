import toDoApp from '../../../Images/Projects/Categories/reactNative/toDoApp.webp';
import toDo1 from '../../../Images/Projects/Categories/reactNative/toDo1.png';


const reactNativeArray = [
    {
        title: 'ToDo App',
        image: toDoApp,
        description: 'Stay organized and productive with this intuitive to-do app, designed for both Android and iOS, to help you manage tasks effortlessly on the go.',
        comingSoon: 'Cooming Soon',
        smallTitle1: '1. Authentication',
        text1: '\n' +
            '    Users can authenticate using credentials fetched from a specified endpoint.\n' +
            '    If the user is logged in, they have the option to log out.\n',
        projectImage1: toDo1,
        smallTitle2: '2. Tab Navigation',
        text2: 'Implemented two tabs using react-navigation:\n' +
            '\n' +
            '    Tab 1: Allows users to add new To-Do items.\n' +
            '    Tab 2: Enables users to view, update and delete To-Do items.\n',
    },
];

export default reactNativeArray;