import React from 'react';
import {useParams} from 'react-router-dom';
import {Box} from '@mui/material';
import CustomTypography from '../../../Components/CustomTypography';
import itSecurityArray from "../Data/itSecurityArray";
import javaArray from "../Data/javaArray";
import pythonArray from "../Data/pythonArray";
import reactArray from "../Data/reactArray";
import reactNativeArray from "../Data/reactNativeArray";
import solidityArray from "../Data/solidityArray";

const SpecificProjectScreen = () => {
    const {projectId, projectName} = useParams();

    console.log('projectId: ', projectId);
    console.log('projectName: ', projectName);


    const categoryMap = {
        python: pythonArray,
        react: reactArray,
        java: javaArray,
        "react native": reactNativeArray, // für react native
        "it security": itSecurityArray,    // für IT security
        solidity: solidityArray,
    };

    const selectedCategory = categoryMap[projectId?.toLowerCase()];
    console.log('selectedCategory: ', selectedCategory);
    const project = selectedCategory?.find(proj => proj.title.toLowerCase() === projectName.toLowerCase());

    if (!project) {
        return <div>Project not found</div>;
    }


    return (
        <Box sx={{
            padding: '20px',
            minHeight: '100vh',
            height: 'auto',
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column', // Setzt den Inhalt vertikal an
            justifyContent: 'flex-start', // Zentriert den Inhalt vertikal
            alignItems: 'center', // Zentriert den Inhalt horizontal
            textAlign: 'center',
        }}
        >
            <Box
                sx={{
                    width: {
                        xs: 'calc(100% - 10px)',  // Volle Breite bei kleinen Bildschirmen
                        sm: 'calc(100% - 10px)',  // Zwei Spalten, mit Platz für den Abstand
                        md: 'calc(100% - 10px)',  // Drei Spalten
                        lg: 'calc(100% - 10px)', // Wieder zwei Spalten bei größeren Bildschirmen
                    },
                    height: {
                        xs: '100px',
                        sm: '130px',
                        md: '140px',
                        lg: '150px',
                    },
                    backgroundImage: `url(${project.image})`,
                    backgroundSize: 'contain',  // Bild skaliert sich, um die Box zu füllen
                    backgroundPosition: 'center',  // Zentriert das Bild in der Box
                    borderRadius: '12px',  // Runden der Box-Ecken
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  // Optional: Schatten für mehr Tiefe
                    display: 'flex',  // Flexbox aktivieren
                    justifyContent: 'center',  // Horizontale Zentrierung
                    alignItems: 'center',  // Vertikale Zentrierung
                    color: 'black',  // Textfarbe, damit der Text lesbar ist
                    textShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',  // Optional: Schatten hinter dem Text für bessere Lesbarkeit
                }}
            >
                <CustomTypography variant="h1"
                                  sx={{
                                      textAlign: 'center',
                                      '-webkit-text-stroke': '1px white',
                                      textStroke: '1px white',
                                      color: 'white'
                                  }}>
                        <span
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.9)',  // Schwarzer Hintergrund mit 50% Transparenz
                                padding: '4px 8px',  // Innenabstand für bessere Lesbarkeit
                                borderRadius: '10px',  // Runde Ecken
                            }}
                        >
                            {project.title}
                        </span>
                </CustomTypography>
            </Box>
            <Box sx={{paddingTop: '20px', paddingBottom: "40px"}}>
                <CustomTypography variant="h4">{project.description}</CustomTypography>
            </Box>
            {project.comingSoon && (
                <Box sx={{paddingTop: '20px', paddingBottom: "40px"}}>
                    <CustomTypography variant="h2">{project.comingSoon}</CustomTypography>
                </Box>
            )}

            {/*TITEL ONE*/}
            {project.smallTitle1 && (
                <Box sx={{paddingTop: '20px', paddingBottom: "10px"}}>
                    <CustomTypography variant="h4">{project.smallTitle1}</CustomTypography>
                </Box>
            )}
            {project.text1 && (
                <Box sx={{paddingTop: '10px', paddingBottom: "10px"}}>
                    <CustomTypography variant="h5">{project.text1}</CustomTypography>
                </Box>
            )}
            {project.projectImage1 && (
                <Box
                    sx={{
                        paddingTop: '10px',
                        paddingBottom: '40px',
                        width: {
                            xs: '100%',  // Volle Breite bei kleinen Bildschirmen
                            sm: 'calc(100% - 10px)',  // Zwei Spalten, mit Platz für den Abstand
                            md: 'calc(100% - 10px)',  // Drei Spalten
                            lg: 'calc(100% - 10px)',  // Wieder zwei Spalten bei größeren Bildschirmen
                        },
                        height: {
                            xs: '500px', // Dynamische Höhe für kleinere Bildschirme
                            sm: '600px', // Höhere Box für größere Bildschirme
                            md: '700px',
                            lg: '800px',
                        },
                        backgroundImage: `url(${project.projectImage1})`,
                        backgroundSize: 'contain', // Passt das Bild in die Box ohne Zuschneiden, bleibt proportional
                        backgroundPosition: 'center', // Zentriert das Bild in der Box
                        backgroundRepeat: 'no-repeat', // Verhindert das Wiederholen des Bildes
                        borderRadius: '10px', // Optionale abgerundete Ecken
                    }}
                />

            )}

            {/*TITEL TWO*/}
            {project.smallTitle2 && (
                <Box sx={{paddingTop: '20px', paddingBottom: "10px"}}>
                    <CustomTypography variant="h4">{project.smallTitle2}</CustomTypography>
                </Box>
            )}
            {project.text2 && (
                <Box sx={{paddingTop: '10px', paddingBottom: "10px"}}>
                    <CustomTypography variant="h5">{project.text2}</CustomTypography>
                </Box>
            )}
            {project.projectImage2 && (
                <Box
                    sx={{
                        paddingTop: '10px',
                        paddingBottom: '40px',
                        width: {
                            xs: '100%',  // Volle Breite bei kleinen Bildschirmen
                            sm: 'calc(100% - 10px)',  // Zwei Spalten, mit Platz für den Abstand
                            md: 'calc(100% - 10px)',  // Drei Spalten
                            lg: 'calc(100% - 10px)',  // Wieder zwei Spalten bei größeren Bildschirmen
                        },
                        height: {
                            xs: '500px', // Dynamische Höhe für kleinere Bildschirme
                            sm: '600px', // Höhere Box für größere Bildschirme
                            md: '700px',
                            lg: '800px',
                        },
                        backgroundImage: `url(${project.projectImage2})`,
                        backgroundSize: 'contain', // Passt das Bild in die Box ohne Zuschneiden, bleibt proportional
                        backgroundPosition: 'center', // Zentriert das Bild in der Box
                        backgroundRepeat: 'no-repeat', // Verhindert das Wiederholen des Bildes
                        borderRadius: '10px', // Optionale abgerundete Ecken
                    }}
                />

            )}


        </Box>
    );
}

export default SpecificProjectScreen;